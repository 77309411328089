import 'bootstrap';
import loadingTemplate from '../views/loading-overlay.pug';
import '../css/paf-loader.css';

export const updateLoadingAnimationText = (textContent) => {
  $('.loading-div-row span').html(`\n${textContent}`);
};

// add a overlaying loading div with animated spiral and some text:
// TODO this styling is super hacky. Put it in CSS
export const addLoadingAnimation = (divToAddOn, textContent) => {
  const existingDiv = $('.loading-div');
  if (existingDiv.length > 0) {
    const count = parseInt(existingDiv.attr('count'), 10);
    existingDiv.attr('count', count + 1);
    if (typeof textContent !== 'undefined') {
      updateLoadingAnimationText(textContent);
    }
    return;
  }
  $(divToAddOn).append(loadingTemplate({ textContent }));
  $('.loading-div').attr('count', 0);
};

// remove/hide loading selector
export const forceRemoveLoadingAnimation = () => {
  $('.loading-div').remove();
};

export const removeLoadingAnimation = () => {
  const existingDiv = $('.loading-div');
  if (existingDiv.length > 0) {
    const count = parseInt(existingDiv.attr('count'), 10);
    if (count === 0) {
      existingDiv.remove();
    } else {
      existingDiv.attr('count', count - 1);
    }
  }
};
